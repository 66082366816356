import NiceModal from '@ebay/nice-modal-react';
import MyAntdModal from './Modal';
import { useEffect, useState } from 'react';
import axios from 'axios';

function App() {
  const [modalCar, setModalCar] = useState(null)

  const [contactLoading, setContactLoading] = useState(false);
  const [contactName, setContactName] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [contactMessage, setContactMessage] = useState('');
  const [contactState, setContactState] = useState('');


  const [storePhone, setStorePhone] = useState('');
  const [storeName, setStoreName] = useState('');
  const [storeState, setStoreState] = useState('');
  const [storeLoading, setStoreLoading] = useState(false);

  useEffect(() => {
    console.log(modalCar);
    if (modalCar !== null) {
      NiceModal.show(MyAntdModal, { car: modalCar, onStoreConfirm: sendMailModal })
    }
  }, [modalCar])

  const showAntdModal = (e) => {
    e.preventDefault()
  };


  const sendMailContact = (e) => {
    e.preventDefault()
    setContactLoading(true)

    if (contactName !== '' && contactPhone !== '' && contactMessage !== "") {
      let text = "Nouveau Contact\nNom et Prénoms: " + contactName + "\nContact: " + contactPhone + "\nMessage: " + contactMessage;
      axios.get("https://api.callmebot.com/whatsapp.php?phone=22553981497&apikey=3531823&text=" + encodeURIComponent(text)).then((data) => {
        console.log(data)

        setContactLoading(false)
        setContactState('success')

        setContactName('')
        setContactPhone('')
        setContactMessage('')

        setTimeout(() => {
          setContactState('')
        }, 2500)
      }).catch((error) => {
        console.log(error);

        setContactLoading(false)
        setContactState('success')

        setContactName('')
        setContactPhone('')
        setContactMessage('')

        setTimeout(() => {
          setContactState('')
        }, 2500)
      });
    }
  }

  const sendMailModal = (storeName, storePhone) => {
    if (storeName !== '' && storePhone !== '') {
      let text = "Demande d'achat véhicule\nNom et Prénoms: " + storeName + "\nContact: " + storePhone + "\nVéhicule: " + modalCar.name;
      axios.get("https://api.callmebot.com/whatsapp.php?phone=22553981497&apikey=3531823&text=" + encodeURIComponent(text)).then((data) => {
        console.log(data)
      }).catch((error) => {
        console.log(error);
      });
    }
  }

  const cars = [
    {
      name: "Suzuki Dzire",
      image: "1.png",
      price: "9.000.000 F",
      payback: "450.000 F / Mois"
    },
    {
      name: "Kia Pegas",
      image: "3.png",
      price: "11.000.000 F",
      payback: "500.000 F / Mois"
    },
    {
      name: "Suzuki S-Presso",
      image: "2.png",
      price: "8.000.000 F",
      payback: "450.000 F / Mois"
    },
    {
      name: "Suzuki Swift",
      image: "6.png",
      price: "9.500.000 F",
      payback: "450.000 F / Mois"
    },
    {
      name: "Suzuki Celerio",
      image: "5.png",
      price: "8.500.000 F",
      payback: "450.000 F / Mois"
    },
    {
      name: "Suzuki Alto",
      image: "4.png",
      price: "6.000.000 F",
      payback: "350.000 F / Mois"
    }
  ]

  const displayCars = []

  cars.forEach((car) => {
    displayCars.push(
      <div onClick={($event) => { setModalCar(car); showAntdModal($event) }} className="col-md-4 mb-4">
        <div className="card overflow-hidden shadow"> <img className="card-img-top" src={"assets/img/dest/" + car.image} alt="{car.name}" />
          <div className="card-body py-4 px-3">
            <div className="d-flex flex-column flex-lg-row justify-content-between mb-3">
              <h4 className="text-secondary fw-medium"><a className="link-900 text-decoration-none stretched-link" href="#!" onClick={($event) => { setModalCar(car); showAntdModal($event) }}>{car.name}</a></h4><span className="fs-1 fw-medium">{car.price}</span>
            </div>
            <div className="d-flex align-items-center"> <img src="assets/img/dest/save-money.png" style={{ marginRight: "14px" }} width="20" alt="navigation" /><span className="fs-0 fw-medium">Jusqu'à {car.payback}</span></div>
          </div>
        </div>
      </div>
    )
  })

  return (
    <>
      <main className="main" id="top">
        <nav style={{ background: "white", zIndex: 999 }} className="navbar navbar-expand-lg navbar-light fixed-top py-5 d-block" data-navbar-on-scroll="data-navbar-on-scroll">
          <div className="container"><a className="navbar-brand" href="/"><img src="logo.png" height="60" alt="logo" /></a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"> </span></button>
            <div className="collapse navbar-collapse border-top border-lg-0 mt-4 mt-lg-0" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto pt-2 pt-lg-0 font-base align-items-lg-center align-items-start">
                <li className="nav-item px-3 px-xl-4"><a className="nav-link fw-medium" aria-current="page" href="#service">Services</a></li>
                <li className="nav-item px-3 px-xl-4"><a className="nav-link fw-medium" aria-current="page" href="#vehicles">Véhicules</a></li>
                <li className="nav-item px-3 px-xl-4"><a className="nav-link fw-medium" aria-current="page" href="#testimonial">Témoignages</a></li>
                <li className="nav-item px-3 px-xl-4"><a className="btn btn-outline-dark order-1 order-lg-0 fw-medium" href="#contact">Contactez-Nous</a></li>
              </ul>
            </div>
          </div>
        </nav>

        <section style={{ paddingTop: "7rem" }}>
          <div className="bg-holder" style={{ backgroundImage: "url(assets/img/hero/hero-bg.svg)" }}>
          </div>

          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-5 col-lg-6 order-0 order-md-1 text-end"><img className="pt-7 pt-md-0 hero-img" src="assets/img/hero/hero-img.png" alt="hero-header" /></div>
              <div className="col-md-7 col-lg-6 text-md-start text-center py-6">
                <h4 className="fw-bold text-danger mb-3">Une gestion professionnelle pour une croissance réussie.</h4>
                <h1 className="hero-title">Optimisez votre flotte, maximisez vos profits.</h1>
                <p className="mb-4 fw-medium">Nous sommes dévoués à optimiser le rendement de vos VTC. De la maintenance préventive à la gestion opérationnelle, nous veillons à ce que chaque détail soit pris en charge. Confiez-nous votre flotte et reposez-vous, nous nous occupons du reste.</p>
                <div className="text-center text-md-start"> <a className="btn btn-primary btn-lg me-md-4 mb-3 mb-md-0 border-0 primary-btn-shadow" href="#!" role="button">Je me lance</a>
                  <div className="w-100 d-block d-md-none"></div><a href="#!" role="button" data-bs-toggle="modal" data-bs-target="#popupVideo"><span className="btn btn-danger round-btn-lg rounded-circle me-3 danger-btn-shadow"> <img src="assets/img/hero/play.svg" width="15" alt="paly" /></span></a><span className="fw-medium">Spot Pub</span>
                  <div className="modal fade" id="popupVideo" tabindex="-1" aria-labelledby="popupVideo" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                      <div className="modal-content">
                        <iframe className="rounded" style={{ width: "100%", maxHeight: "500px" }} height="500px" src="https://www.youtube.com/embed/_lhdhL4UDIo" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen"></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-5 pt-md-9" id="service">

          <div className="container">
            <div className="position-absolute z-index--1 end-0 d-none d-lg-block"><img src="assets/img/category/shape.svg" style={{ maxWidth: "200px" }} alt="service" /></div>
            <div className="mb-7 text-center">
              <h5 className="text-secondary">Nos Services</h5>
              <h3 className="fs-xl-10 fs-lg-8 fs-7 fw-bold font-cursive text-capitalize">Ce que nous offrons</h3>
            </div>
            <div className="row">
              <div className="col-lg-3 col-sm-6 mb-6">
                <div className="card service-card shadow-hover rounded-3 text-center align-items-center">
                  <div className="card-body p-xxl-5 p-4"> <img src="assets/img/category/icon1.png" width="75" alt="Service" />
                    <h4 className="mb-3">Transport</h4>
                    <p className="mb-0 fw-medium">Nous vous offrons des trajets confortables, fiables et sûrs. Nos chauffeurs professionnels vous conduiront à destination avec ponctualité et courtoisie.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 mb-6">
                <div className="card service-card shadow-hover rounded-3 text-center align-items-center">
                  <div className="card-body p-xxl-5 p-4"> <img src="assets/img/category/icon2.png" width="75" alt="Service" />
                    <h4 className="mb-3">Logistique</h4>
                    <p className="mb-0 fw-medium">Nous assurons le transport de vos marchandises ou de personnes de façon rapide. Avec nous, c'est la garantie d'un déplacement rapide et fiable.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 mb-6">
                <div className="card service-card shadow-hover rounded-3 text-center align-items-center">
                  <div className="card-body p-xxl-5 p-4"> <img src="assets/img/category/icon3.png" width="75" alt="Service" />
                    <h4 className="mb-3">Livraison</h4>
                    <p className="mb-0 fw-medium">Des colis aux repas en passant par des produits, nous sommes là pour vous garantir que vos articles arrivent à destination en toute sécurité et à temps. Simplifiez vos livraisons avec notre équipe dédiée et notre réseau efficace.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 mb-6">
                <div className="card service-card shadow-hover rounded-3 text-center align-items-center">
                  <div className="card-body p-xxl-5 p-4"> <img src="assets/img/category/icon4.png" width="75" alt="Service" />
                    <h4 className="mb-3">Location</h4>
                    <p className="mb-0 fw-medium">Nous mettons à votre disposition une vaste gamme d'options pour répondre à vos besoins. Que vous ayez besoin de louer des véhicules, de l'équipement, ou d'autres articles, nous sommes là pour vous simplifier la vie.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </section>

        <section className="pt-5" id="vehicles">

          <div className="container">
            <div className="position-absolute start-100 bottom-0 translate-middle-x d-none d-xl-block ms-xl-n4"><img src="assets/img/dest/shape.svg" alt="destination" /></div>
            <div className="mb-7 text-center">
              <h5 className="text-secondary">Nos Véhicules</h5>
              <h3 className="fs-xl-10 fs-lg-8 fs-7 fw-bold font-cursive text-capitalize">Vous pouvez les avoir chez nous</h3>
            </div>
            <div className="row">
              {displayCars}
            </div>
          </div>

        </section>

        <section id="testimonial">

          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <div className="mb-8 text-start">
                  <h5 className="text-secondary">Témoignages</h5>
                  <h3 className="fs-xl-10 fs-lg-8 fs-7 fw-bold font-cursive text-capitalize">Ce qu'ils pensent de nous</h3>
                </div>
              </div>
              <div className="col-lg-1"></div>
              <div className="col-lg-6">
                <div className="pe-7 ps-5 ps-lg-0">
                  <div className="carousel slide carousel-fade position-static" id="testimonialIndicator" data-bs-ride="carousel">
                    <div className="carousel-indicators">
                      <button className="active" type="button" data-bs-target="#testimonialIndicator" data-bs-slide-to="0" aria-current="true" aria-label="Testimonial 0"></button>
                      <button className="false" type="button" data-bs-target="#testimonialIndicator" data-bs-slide-to="1" aria-current="true" aria-label="Testimonial 1"></button>
                      <button className="false" type="button" data-bs-target="#testimonialIndicator" data-bs-slide-to="2" aria-current="true" aria-label="Testimonial 2"></button>
                    </div>
                    <div className="carousel-inner">
                      <div className="carousel-item position-relative active">
                        <div className="card shadow" style={{ borderRadius: "10px" }}>
                          <div className="position-absolute start-0 top-0 translate-middle"> <img className="rounded-circle fit-cover" src="assets/img/user.png" height="65" width="65" alt="" /></div>
                          <div className="card-body p-4">
                            <p className="fw-medium mb-4">&quot;J'ai acheté un VTC avec TaxiChap et je dois dire que j'ai été impressionnée. Les chauffeurs sont professionnels et la voiture est toujours impeccable. Je recommande vivement ce service !&quot;</p>
                            <h5 className="text-secondary">Marie-Christine</h5>
                          </div>
                        </div>
                        <div className="card shadow-sm position-absolute top-0 z-index--1 mb-3 w-100 h-100" style={{ borderRadius: "10px", transform: "translate(25px, 25px)" }}> </div>
                      </div>
                      <div className="carousel-item position-relative ">
                        <div className="card shadow" style={{ borderRadius: "10px" }}>
                          <div className="position-absolute start-0 top-0 translate-middle"> <img className="rounded-circle fit-cover" src="assets/img/user.png" height="65" width="65" alt="" /></div>
                          <div className="card-body p-4">
                            <p className="fw-medium mb-4">&quot;J'ai confié la gestion de mon véhicule VTC à cette entreprise, et je suis ravi des résultats. Ils ont pris soin de tout, de la maintenance aux réservations, et j'ai reçu ma redevance chaque mois de manière fiable. Un excellent partenaire pour les propriétaires de VTC.&quot;</p>
                            <h5 className="text-secondary">David</h5>
                          </div>
                        </div>
                        <div className="card shadow-sm position-absolute top-0 z-index--1 mb-3 w-100 h-100" style={{ borderRadius: "10px", transform: "translate(25px, 25px)" }}> </div>
                      </div>
                      <div className="carousel-item position-relative ">
                        <div className="card shadow" style={{ borderRadius: "10px" }}>
                          <div className="position-absolute start-0 top-0 translate-middle"> <img className="rounded-circle fit-cover" src="assets/img/user.png" height="65" width="65" alt="" /></div>
                          <div className="card-body p-4">
                            <p className="fw-medium mb-4">&quot;Cette entreprise de gestion de VTC a rendu ma vie beaucoup plus facile. J'ai confié mon véhicule à leurs soins, et ils ont fait un travail fantastique pour maximiser mes revenus tout en prenant soin de la maintenance. C'est un partenariat gagnant-gagnant, et je suis reconnaissante pour leur service.&quot;</p>
                            <h5 className="text-secondary">Sarah</h5>
                          </div>
                        </div>
                        <div className="card shadow-sm position-absolute top-0 z-index--1 mb-3 w-100 h-100" style={{ borderRadius: "10px", transform: "translate(25px, 25px)" }}> </div>
                      </div>
                    </div>
                    <div className="carousel-navigation d-flex flex-column flex-between-center position-absolute end-0 top-lg-50 bottom-0 translate-middle-y z-index-1 me-3 me-lg-0" style={{ height: "60px", width: "20px" }}>
                      <button className="carousel-control-prev position-static" type="button" data-bs-target="#testimonialIndicator" data-bs-slide="prev"><img src="assets/img/icons/up.svg" width="16" alt="icon" /></button>
                      <button className="carousel-control-next position-static" type="button" data-bs-target="#testimonialIndicator" data-bs-slide="next"><img src="assets/img/icons/down.svg" width="16" alt="icon" /></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </section>

        <section id="contact" className="pt-6">

          <div className="container">
            <div className="py-8 px-5 position-relative text-center" style={{ backgroundColor: "rgba(255, 140, 0, 0.199)", borderRadius: "129px 20px 20px 20px" }}>
              <div className="position-absolute start-100 top-0 translate-middle ms-md-n3 ms-n4 mt-3"> <img src="assets/img/cta/send.png" style={{ maxWidth: "70px" }} alt="send icon" /></div>
              <div className="position-absolute end-0 top-0 z-index--1"> <img src="assets/img/cta/shape-bg2.png" width="264" alt="cta shape" /></div>
              <div className="position-absolute start-0 bottom-0 ms-3 z-index--1 d-none d-sm-block"> <img src="assets/img/cta/shape-bg1.png" style={{ maxWidth: "340px" }} alt="cta shape" /></div>
              <div className="row justify-content-center">

                <div className="col-lg-8 col-md-10">
                  <h2 className="text-secondary lh-1-7 mb-7">Une question ? Une suggestion ? Vous pouvez nous écrire.</h2>
                  <form action='#' onSubmit={(e) => sendMailContact(e)} className="row g-3 align-items-center w-lg-75 mx-auto">
                    <div className="col-12">
                      <div className="input-group-icon">
                        <input onChange={(e) => { setContactName(e.target.value) }} disabled={contactLoading} className="form-control form-little-squirrel-control" type="text" placeholder="Nom & Prénoms" aria-label="name" /><img className="input-box-icon" src="assets/img/cta/mail.svg" width="17" alt="mail" />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="input-group-icon">
                        <input onChange={(e) => { setContactPhone(e.target.value) }} disabled={contactLoading} className="form-control form-little-squirrel-control" type="text" placeholder="Contact" aria-label="contact" /><img className="input-box-icon" src="assets/img/cta/mail.svg" width="17" alt="mail" />
                      </div>
                    </div>
                    <div className="col-12">
                      <textarea onChange={(e) => { setContactMessage(e.target.value) }} disabled={contactLoading} className="form-control" rows={5} placeholder="Votre Message"></textarea>
                    </div>
                    <div className="col-12">
                      <button disabled={contactLoading} type='submit' className="btn btn-danger orange-gradient-btn fs--1">Envoyer</button>
                    </div>

                    <div className="col-lg-12 mt-3">
                      <div style={{ display: contactState === 'success' ? 'block' : 'none' }} className="email-success">
                        <p>Votre message a bien été envoyé !</p>
                      </div>
                      <div style={{ display: contactState === 'error' ? 'block' : 'none' }} className="email-error">
                        <p>Une erreur est survenue ! Veuillez réessayer plus tard.</p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

        </section>

        <div className="py-5 text-center">
          <p className="mb-0 text-primary fs--5 fw-medium">Riviera Bonoumin, Non loin de la paroisse St Joseph l'artisan</p>
          <p className="mb-5 text-primary fs--5 fw-medium"><a href='tel:+2250799546869'>+2250799546869</a></p>
          <p className="mb-0 text-secondary fs--1 fw-medium">Tous droits réservés TaxiChap &copy; 2023 </p>
        </div>
      </main>
    </>
  );
}

export default App;
