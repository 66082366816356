import { Modal } from 'antd';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

export default NiceModal.create(({ car, onStoreConfirm }) => {
    const modal = useModal();
    const storeConfirm = () => {
        modal.hide()

        onStoreConfirm(document.getElementById('storeName').value, document.getElementById('storeContact').value)
    }

    return (
        <Modal
            title={car.name}
            onOk={() => { storeConfirm() }}
            open={modal.visible}
            onCancel={() => modal.hide()}
            afterClose={() => modal.remove()}
            cancelText="Fermer"
            okText="Confirmer"
        >
            <div className='row'>
                <div className='col-12'>
                    <p className='text-center'>
                        <img className="img-fluid" src={"assets/img/dest/" + car.image} alt="{car.name}" />
                    </p>
                    <p style={{ fontSize: "21px" }}>Achetez une <strong style={{ color: "#FF7D68" }}>{car.name}</strong> chez <strong style={{ color: "#FF7D68" }}>TaxiChap</strong> à <strong style={{ color: "#FF7D68" }}>{car.price}</strong> et gagnez jusqu'à <strong style={{ color: "#FF7D68" }}>{car.payback}</strong></p>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6 mb-3'>
                    <input id='storeName' type='text' className='form-control' placeholder='Nom & Prénoms' />
                </div>
                <div className='col-md-6 mb-3'>
                    <input id='storeContact' type='text' className='form-control' placeholder='Contact' />
                </div>
            </div>
        </Modal>
    );
});